
import { Component, Inject, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

import { Breadcrumb } from '@/dsl/atoms/Breadcrumbs'
import { DrawerServiceType, IDrawer} from '@/core/services/drawer'
import { Pageable, AnyObject } from '@/core/general'
import { IModal, ModalServiceType } from '@/core/services/modal'

import { getBreadcrumbsFactoryForRoute } from '@/shared'
import { FilterAware, FilterBadge } from '@/shared/components/molecules/Filter'

import { EventModel } from '../models'
import { EventsRepository } from '../repositories'
import { EventsRepositoryType, IEventsRepository } from '../contracts'
import { EventsModuleDrawersCollection, EventsModuleModalsCollection } from '../config'
import { EventCard } from '../components/organisms/EventCard'
import { AmplitudeType, AppAmplitudeEvent, IAmplitude } from '@/shared/services/amplitude'

@Component<Events>({
  name: 'Events',
  components: { FilterBadge, EventCard },
  mounted(): void {
    this.fetchEvents()
  }
})
export class Events extends FilterAware {
  @Inject({ from: 'isMobile', default: false })
  public isMobile!: () => boolean

  protected readonly drawerService: IDrawer =
    this.$container.get<IDrawer>(DrawerServiceType)

  protected readonly eventsRepository: IEventsRepository =
    this.$container.get<EventsRepository>(EventsRepositoryType)

  protected readonly modalService: IDrawer =
    this.$container.get<IModal>(ModalServiceType)

  protected readonly amplitude =
    this.$container.get<IAmplitude>(AmplitudeType)

  public events: Pageable<EventModel> | null = null
  protected currentPage: number = 1

  public get breadcrumbs(): Breadcrumb[] {
    if (!this.$t) {
      return []
    }

    return getBreadcrumbsFactoryForRoute(this.$route, undefined, this.$t.bind(this))
  }

  public openFiltersDrawer(): void {
    this.drawerService.show(EventsModuleDrawersCollection.FiltersDrawer, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters
    })
  }

  public openFiltersModal (): void {
    this.modalService.show(EventsModuleModalsCollection.FiltersModal, {
      appliedFilters: this.appliedFilters,
      onApply: this.onApplyFilters,
      shouldRedirect: false
    })
  }

  public openFilters (): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_OPEN_FILTERS)
    if (this.isMobile()) {
      this.openFiltersDrawer()
    } else {
      this.openFiltersModal()
    }
  }

  public async loadMore (): Promise<void> {
    if (this.events?.nextPage) {
      let eventsCopy = cloneDeep(this.events)
      const nextPageEvents = await this.eventsRepository.fetchEvents(this.events.nextPage,
        undefined, this.queryParams)

      eventsCopy = {
        ...eventsCopy,
        items: [...eventsCopy.items, ...nextPageEvents.items],
        nextPage: nextPageEvents.nextPage,
        previousPage: nextPageEvents.previousPage
      }

      this.events = eventsCopy
    }
  }

  protected onApplyFilters(filters: AnyObject): void {
    this.amplitude.emit(AppAmplitudeEvent.ON_APPLY_FILTERS)
    this.queryParams = {
      ...this.queryParams,
      ...filters
    }
  }

  protected async fetchEvents(): Promise<void> {
    try {
      this.events = await this.eventsRepository.fetchEvents(this.currentPage, undefined, this.queryParams)
    } catch (e) {
      console.log((e as Error).message)
    }
  }

  @Watch('queryParams')
  protected onQueryParamsChanged(): void {
    this.fetchEvents()
  }
}

export default Events
